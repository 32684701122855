<template>
    <q-dialog v-model="isView" persistent>
        <q-card class="confirm-modal q-pa-lg" style="width: 600px">
            <q-card-section class="row items-center q-pb-none">
                <div class="confirm-modal__title q-mb-md">
                    {{ localizeText('Редактирование') }}
                </div>
                <q-space />
                <q-btn
                    class="confirm-modal__title q-mb-md"
                    icon="close"
                    flat
                    round
                    dense
                    size="16px"
                    v-close-popup
                    @click="closeModal"
                />
            </q-card-section>
            <div v-if="isLoading" class="text-center q-mt-xl">
                <q-spinner-dots color="primary" size="4em" />
            </div>
            <template v-else>
                <div class="q-pb-md">
                    <q-input
                        :rules="[ val => val.length > 0 || enterNameChatText, val => val.length <= 190 || invalidLengthNameChatText ]"
                        v-model="nameChat"
                        :label="nameChatText"
                        class="high-field q-pb-lg"
                    />

                    <ui-select
                        v-if="isRoomChat"
                        dense
                        options-dense
                        v-model="selectedRoomAccessType"
                        :options="accessOptions"
                        :rules="[ isRequired ]"
                        map-options
                        emit-value
                        popup-content-class="select"
                        :label="localizeText('Доступ к комнате') + '*'"
                        class="high-field q-pb-lg"
                    />

                    <DataSelect
                        v-if="!isRoomChat"
                        v-model="selectedCohorts"
                        :label="localizeText('Потоки')"
                        :load-action="loadCohortsAction"
                        multiple
                        class="high-field q-pb-lg"
                    />

                    <DataSelect
                        v-if="!isRoomChat || selectedRoomAccessType === RoomAccessType.ConcreteStudents"
                        v-model="selectedGroups"
                        :label="localizeText('Группы')"
                        :load-action="loadGroupsAction"
                        multiple
                        class="high-field q-pb-lg"
                    />

                    <UserMultiselect
                        v-if="!isRoomChat || selectedRoomAccessType === RoomAccessType.ConcreteStudents"
                        v-model="selectedUsers"
                        class="high-field q-pb-lg"
                        :label="localizeText('Пользователи')"
                        :discipline-id="isRoomChat ? disciplineId : undefined"
                        :search-request-type="isRoomChat ? UsersForSelectRequestType.StudentsByDiscipline : UsersForSelectRequestType.Users"
                    />

                    <UserMultiselect
                        v-if="isGroupChat"
                        v-model="selectedAdmins"
                        class="high-field q-pb-lg"
                        :label="localizeText('Администратор чата')+'*'"
                    />

                </div>
                <div v-if="isGroupChat" class="field-description q-mb-lg q-pr-lg q-mr-xl">
                    {{ localizeText('Звонки в групповых чатах доступны, если количество участников чата не превышает 500 человек') }}
                </div>
            </template>

            <div class="row items-center justify-end">
                <q-btn
                    :label="localizeText('Отмена')"
                    color="primary"
                    flat
                    @click="closeModal"
                />
                <q-btn
                    color="primary"
                    class="q-manual-focusable--focused"
                    @click="saveChat"
                >
                    {{ localizeText('Сохранить') }}
                </q-btn>
            </div>
        </q-card>
    </q-dialog>
</template>

<script lang="ts">
    import { Notify } from 'quasar';
    import { localize } from 'src/services/LocalizationService';
    import UserMultiselect from 'components/ui/selects/UserMultiselect/UserMultiselect.vue';
    import {
        AddEditChatRoomRequestModel,
        ChatBaseInfoResponseModel,
        ChatClient,
        ChatEditRequestModel,
        ChatType,
        CohortClient,
        DropDownItem,
        GroupClient,
        ListChatDto,
        PagedResultOfDropDownItem,
        ResultOf,
        RoleEnum,
        RoutePageNameEnum,
        UserSelectModel,
        UsersForSelectRequestType,
    } from 'src/api/ApiClient';
    import { getApiClientInitialParams } from 'src/api/BaseApiClient';
    import { NotifyErrors } from 'src/api/ResultOfMethods';
    import { IChatRoom, RoomAccessType } from 'pages/Main/Chat/types/interfaces';
    import DataSelect from 'components/ui/selects/DataSelect/DataSelect.vue';
    import { Common } from 'src/helpers/Common';
    import { computed, defineComponent, onMounted, PropType, ref, toRefs } from 'vue';
    import { useRouter } from 'vue-router';
    import useValidationRules from 'src/helpers/custom-hooks/useValidationRules';
    import { useAccountStore } from 'src/store/module-account';
    import { useChatStore } from 'src/store/module-chat';


    export default defineComponent({
        name: 'EditChat',

        components: {
            DataSelect,
            UserMultiselect,
        },

        emits: ['on-set-is-show-add-edin-chat-modal'],

        props: {
            // Модель базовой информации о чате
            chatInfo: {
                type: Object as PropType<ChatBaseInfoResponseModel>,
                required: true,
            },
        },

        // eslint-disable-next-line max-lines-per-function
        setup(props, context) {
            const $router = useRouter();
            const chatStore = useChatStore();
            const accountStore = useAccountStore();
            const chatInfoRef = toRefs(props.chatInfo);
            const { isRequired } = useValidationRules();

            const nameChat = ref<string>('');
            const isLoading = ref<boolean>(true);

            //region Массивы выбранных элементов для селектов
            const selectedCohorts = ref<DropDownItem[] | null>([]);
            const selectedGroups = ref<DropDownItem[] | null>([]);
            const selectedUsers = ref<UserSelectModel[] | null>([]);
            const selectedAdmins = ref<UserSelectModel[] | null>([]);
            const selectedRoomAccessType = ref<number | null>(null);
            //endregion

            // флаг показа модального окна
            const isView = ref<boolean>(false);
            const disciplineId = ref<number | undefined>();

            let currentChatId: number = 0;
            let roomChatId: number = 0;

            const accessOptions: DropDownItem[] = [{
                text: localizeText('Всем участникам дисциплины'),
                value: RoomAccessType.AllContingent
            }, {
                text: localizeText('Отдельным студентам'),
                value: RoomAccessType.ConcreteStudents
            }];

            // Текущий пользователь имеет роль "Суперадминистратор"
            const isSuperAdmin: boolean = Common.isHaveRole(RoleEnum.SuperAdministratorRole);

            // если есть disciplineChatId - значит это комната
            const isRoomChat = computed<boolean>(() => {
                return !!props.chatInfo.disciplineChatId || !!props.chatInfo.disciplineId;
            });

            // флаг группового чата
            const isGroupChat = computed<boolean>(() => {
                return props.chatInfo.type === ChatType.Group;
            });

            const nameChatText = computed<string>(() => {
                return isRoomChat.value ? localizeText('Название комнаты') : localizeText('Название чата');
            });

            const enterNameChatText = computed<string>(() => {
                return isRoomChat.value ? localizeText('Введите название комнаты') : localizeText('Введите название чата');
            });

            // Ограничиваем название чата длинной в 190 символов
            const invalidLengthNameChatText = computed<string>(() => {
                return localizeText('Название чата должно быть меньше {count} символов', { count: 190 });
            });

            //region Экшены для получения данных для селектов
            const loadCohortsAction = computed(() => {
                const api = new CohortClient(getApiClientInitialParams());
                return api.getCohorts.bind(api);
            });

            async function loadGroupsAction(search: string | null, page: number, ids: number[] | null = null): Promise<ResultOf<PagedResultOfDropDownItem>> {
                const api = new GroupClient(getApiClientInitialParams());
                return api.getGroups(search, page, ids, null, disciplineId.value);
            }
            //endregion

            // Локализация для компонента
            function localizeText(text: string, formatterArguments: Record<string, string | number> | null = null): string {
                return localize(text, formatterArguments);
            }

            // Вызываем событие чтобы родительский компонент его обработал
            function closeModal(): void {
                context.emit('on-set-is-show-add-edin-chat-modal', false);
            }

            // Валидация данных
            function isValidData(): boolean {
                if (!isValidTypeChat()) {
                    Notify.create({
                        type: 'negative',
                        message: localizeText('Личные чаты и чат с собой нельзя редактировать'),
                    });
                    return false;
                }

                let isValid = isValidModel();

                if (!isValid) {
                    Notify.create({
                        type: 'negative',
                        message: localizeText('Название чата является обязательным'),
                    });

                    return false;
                } else {
                    if (isRoomChat.value) {
                        isValid = selectedRoomAccessType.value !== null && selectedRoomAccessType.value !== undefined;

                        if (!isValid) {
                            Notify.create({
                                type: 'negative',
                                message: localizeText('Выберите тип доступа к комнате'),
                            });
                        }

                        return isValid;
                    }
                }

                return true;
            }

            // Сохранить изменения при редактировании чата
            async function saveChat(): Promise<void> {
                if (!isValidData()) {
                    return;
                }

                const model: ChatEditRequestModel = {
                    cohorts: selectedCohorts.value,
                    groups: selectedGroups.value,
                    id: currentChatId,
                    name: nameChat.value,
                    users: undefined,
                    userIds: convertUsersSelectModelToUsersList(selectedUsers.value),
                    type: props.chatInfo?.type,
                    admins: undefined,
                    adminIds: convertUsersSelectModelToUsersList(selectedAdmins.value),
                };

                let result;
                let chatId;

                // Посылаем запрос в зависимости от того чта комнаты это или просто чат
                if (isRoomChat.value) {
                    chatId = currentChatId;
                    result = await new ChatClient(getApiClientInitialParams()).editChatRoomForDiscipline(convertEditChatModelToRoomChatModel(model));
                } else {
                    result = await new ChatClient(getApiClientInitialParams()).editPOST(model);
                    chatId = result.entity;
                }

                if (!result.isSuccess) {
                    NotifyErrors(result);
                } else {
                    let isNeedRedirect = false;

                    if (model.type === ChatType.Group) {
                        const userId = accountStore.getAccountInfo?.id ?? 0;
                        isNeedRedirect = !model.adminIds?.find((x: number) => x === userId) && !model.userIds?.find((x: number) => x === userId);
                    }

                    await updateChatData(chatId, isNeedRedirect);
                }
            }

            async function updateChatData(chatId: number, isNeedRedirect: boolean): Promise<void> {
                const chatResult = await new ChatClient(getApiClientInitialParams()).getChatBaseInfo(chatId);

                const chat: ListChatDto = {
                    chatId: chatResult.entity.id,
                    name: chatResult.entity.nameChat,
                    type: chatResult.entity.type,
                    countMembers: chatResult.entity.countMembers,
                    isDisabled: false,
                    parentChatType: 0,
                    isMutedDiscipline: false,
                    isMuted: chatResult.entity.isMuted,
                };

                // Обновляем информацию о чате в сторе
                if (isRoomChat.value) {
                    const room = chat as IChatRoom;
                    room.disciplineChatId = props.chatInfo.disciplineChatId || null;
                    chatStore.updateRoomInDisciplineChat(room);
                } else {
                    chatStore.editChat(chat);
                }

                chatInfoRef.nameChat.value = chat.name;
                chatInfoRef.countMembers.value = chatResult.entity.countMembers;
                chatInfoRef.userAvatars.value = chatResult.entity.userAvatars;
                chatInfoRef.canEdit.value = chatResult.entity.canEdit;
                chatInfoRef.isCallsEnabled.value = chatResult.entity.isCallsEnabled;

                Notify.create({
                    type: 'positive',
                    message: localizeText('Чат успешно изменен'),
                });

                isView.value = false;

                // Если удалили себя из группового чата, то нужно сделать редирект на личный чат
                // и удалить групповой чат из списка
                if (isNeedRedirect) {
                    chatStore.removeChat(chat);
                    const myselfChatId = accountStore.accountInfo?.myselfChatId;
                    await $router.push({
                        name: Common.getRouteName(RoutePageNameEnum.Chat),
                        params: { id: myselfChatId?.toString() },
                    });
                }

                // вызываем событие чтобы родительский компонент его обработал
                context.emit('on-set-is-show-add-edin-chat-modal', false);
            }

            function isValidModel(): boolean {
                if (!nameChat.value.trim()) {
                    return false;
                }

                return nameChat.value.length <= 190;
            }

            function isValidTypeChat(): boolean {
                // Личные чаты и чат с собой нельзя редактировать
                if (props.chatInfo.type === ChatType.Private || props.chatInfo.type === ChatType.Self) {
                    return false;
                }
                return true;
            }

            function convertUsersSelectModelToUsersList(model: UserSelectModel[] | null): number[] | undefined {
                if (!model) {
                    return undefined;
                }
                const result: number[] = [];

                model.forEach((x: UserSelectModel) => {
                    const add: number = x.id;
                    result.push(add);
                });
                return result;
            }

            function convertEditChatModelToRoomChatModel(chat: ChatEditRequestModel): AddEditChatRoomRequestModel {
                const isClosed = selectedRoomAccessType.value === RoomAccessType.ConcreteStudents;

                return {
                    chatRoomId: roomChatId,
                    nameChat: chat.name?.trim() ?? '',
                    studentIds: isClosed ? (chat.users?.map((x: UserSelectModel) => x.id) || chat.userIds || []) : [],
                    cohortIds: [],
                    groupIds: isClosed ? (chat.groups?.map((x: DropDownItem) => x.value) || []) : [],
                    isClosed,
                    disciplineId: props.chatInfo.disciplineChatId!,
                    isContingentFromDiscipline: selectedRoomAccessType.value === RoomAccessType.AllContingent,
                };
            }

            onMounted(async () => {
                currentChatId = props.chatInfo.id;
                isView.value = true;
                disciplineId.value = props.chatInfo.disciplineId ?? undefined;

                const result = await new ChatClient(getApiClientInitialParams()).editGET(currentChatId);

                if (result.isSuccess) {
                    selectedCohorts.value = result.entity.cohorts ?? null;
                    selectedGroups.value = result.entity.groups ?? null;
                    selectedUsers.value = result.entity.users ?? null;
                    selectedAdmins.value = result.entity.admins ?? null;
                    nameChat.value = result.entity.name ?? '';
                    roomChatId = result.entity.chatRoomId || 0;

                    if (result.entity.isClosed) {
                        selectedRoomAccessType.value = RoomAccessType.ConcreteStudents;
                    } else {
                        selectedRoomAccessType.value = RoomAccessType.AllContingent;
                    }      
                }

                isLoading.value = false;
            });

            return {
                isRequired,
                accessOptions,
                selectedRoomAccessType,
                RoomAccessType,
                nameChat,
                isLoading,
                selectedCohorts,
                selectedGroups,
                selectedUsers,
                isView,
                isRoomChat,
                nameChatText,
                enterNameChatText,
                invalidLengthNameChatText,
                isSuperAdmin,
                loadCohortsAction,
                loadGroupsAction,
                localizeText,
                closeModal,
                saveChat,
                isGroupChat,
                selectedAdmins,
                disciplineId,
                UsersForSelectRequestType,
            };
        },

    });
</script>
